import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'hash',
  //mode: 'history',
  base: process.env.BASE_URL,
  routes: [

   
    { 
      path: '*', 
      //component: NotFoundComponent,
      component: () => import('@/views/dashboard/Page404'),
      name: 'NotFound'
    },


   
    
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),

      //component: () => import('@/views/dashboard/Login'),
      children: [
        // Dashboard

        { 
          name: 'Controle de Acesso',
          component: () => import('@/views/dashboard/Restrito'),
          path: 'restrito'
        },

       
        {
          name: 'Register',
          path: 'register',
          component: () => import('@/views/dashboard/Register'),
        },

         // Pages
         {
          name: 'Usuário',
          path: 'usuario',
          component: () => import('@/views/dashboard/Usuario'),
        },

           // Pages
        {
            name: 'Consulta',
            path: 'consulta',
            component: () => import('@/views/dashboard/Consulta'),
        },

   
       

    
      ],
      
      
    },
    {
      path: '/login',
      component: () => import('@/views/dashboard/Login'),
    },
  
    {
      path: '/restrito/atualizacao',
      component: () => import('@/views/restrito/Atualizacao'),
    },

  ],
})
