import axios from 'axios'
//const url = 'http://localhost:8099/'
const url = 'https://maestrix.grupoparque.com.br:8443/api-consulta-ivertex/'
//const url_dash = 'https://api.gpdf.com.br/apisocio/api/'

export const http = axios.create({
     baseURL: url,
})

http.defaults.timeout = 1200000;

http.interceptors.response.use(function (response) {
     return response;
   }, function (error) {
     return Promise.reject(error);
   });


export default {
     url, 
}